import { Footer } from "../footer";
import { HEADER_ITEMS } from "../header";
import { Header, HeaderOffset } from "@cm-websites/common";
import React from "react";

export type NavigationAndFooterLayoutProps = { children: React.ReactNode };

export const NavigationAndFooterLayout: React.FC<NavigationAndFooterLayoutProps> = ({ children }) => (
  <React.Fragment>
    <Header items={HEADER_ITEMS} />
    <HeaderOffset />
    {children}
    <Footer />
  </React.Fragment>
);
