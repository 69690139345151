import { Container, Link, Typography } from "@mui/material";
import { NavigationAndFooterLayout } from "../layouts";
import { NextPageWithLayout } from "@cm-websites/common";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import React from "react";
import type { GetStaticProps } from "next";

export const NotFound404Page: NextPageWithLayout = () => (
  <React.Fragment>
    <Container
      maxWidth="md"
      sx={{ pt: { xs: 12, sm: 14, lg: 16 }, pb: { xs: 8, sm: 10, lg: 14, xl: 16 } }}
    >
      <Typography variant="h1" sx={{ pb: 6 }}>
        Apologies
      </Typography>
      <Typography variant="body1" paragraph>
        Something has gone thoroughly wrong here. We could not find the page you were looking for. Please
        use the navigation to get to another page.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions, you can always contact us at{" "}
        <Link href="mailto:support@culinario-mortale.com">support@culinario-mortale.com</Link>.
      </Typography>
    </Container>
  </React.Fragment>
);

NotFound404Page.getLayout = (page) => <NavigationAndFooterLayout>{page}</NavigationAndFooterLayout>;

export default NotFound404Page;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(locale
        ? await serverSideTranslations(locale, ["c-common", "c-user-consent-management"], i18nConfig)
        : {}),
    },
  };
};
